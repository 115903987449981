import React, { useMemo } from 'react'

import router from 'next/router'
import NextLink from 'next/link'

import {
    Box,
    Flex,
    Text,
    Stack,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    Menu,
    MenuButton,
    Avatar,
    AvatarBadge,
    MenuList,
    MenuItem,
    Link,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { AiOutlineLock } from 'react-icons/ai'

import { useAuth } from '@/hooks/auth'
import { WhiteSymbol } from '../logos/WhiteSymbol'
import { colors, ROLES } from '@/utils/const'
import { getExtensionRequestUser } from '@/lib/profiles'

interface NavItem {
    label: string
    subLabel?: string
    children?: Array<NavItem>
    href?: string
}

const NAV_ITEMS: Array<NavItem> = [
    {
        label: 'Oportunidades',
        href: '/',
    },
    {
        label: 'Gestión de bono',
        children: [
            {
                label: 'Bonos',
                subLabel: 'Listado de bonos',
                href: '/bonus',
            },
            {
                label: 'Soluciones',
                subLabel: 'Listado de soluciones',
                href: '/solutions',
            },
            {
                label: 'Tareas',
                subLabel: 'Listado de tareas',
                href: '/tasks',
            },
        ],
    },
    {
        label: 'Distribuidores',
        children: [
            {
                label: 'Distribuidores',
                subLabel: 'Listado de distribuidores',
                href: '/distributors',
            },
            {
                label: 'Campañas',
                subLabel: 'Listado de campañas',
                href: '/campaign',
            },
        ],
    },
    {
        label: 'Administración',
        children: [
            {
                label: 'Clientes',
                subLabel: 'Listado de clientes',
                href: '/clients',
            },
            {
                label: 'Usuarios',
                subLabel: 'Listado de usuarios',
                href: '/users',
            },
            {
                label: 'Remesas',
                subLabel: 'Listado de remesas',
                href: '/remittances',
            },
            {
                label: 'Dashboard',
                subLabel: 'Detalle empresarial',
                href: '/dashboard',
            },
        ],
    },
    {
        label: 'Ampliación de bonos',
        href: '/bonus-extension',
    },
]

const EXTENSION_REQUEST_MENUS = ['/bonus', '/opportunities']

const COMMERCIAL_ADMIN_ROUTES = [
    '/bonus',
    '/tasks',
    '/solutions',
    '/distributors',
    '/campaign',
    '/clients',
]

const COMMERCIAL_ROUTES = [
    '/solutions',
    // '/distributors',
    // '/campaign',
    '/clients',
]

export const Navbar = () => {
    const { user, logout } = useAuth({ middleware: 'auth' })

    return (
        <Box>
            <Flex
                bg={colors['gradient-e']}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 8 }}
                borderBottom={1}
                borderStyle={'solid'}
                align={'center'}>
                <Flex
                    flex={{ base: 1 }}
                    justify={{ base: 'center', md: 'start' }}>
                    <NextLink href="/">
                        <Link>
                            <WhiteSymbol width={50} height={50} />
                        </Link>
                    </NextLink>

                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <DesktopNav />
                    </Flex>
                </Flex>

                <Menu>
                    <MenuButton aria-label="Options">
                        <div className="w-10">
                            <Avatar
                                name={user?.name}
                                src="https://bit.ly/broken-link">
                                <AvatarBadge
                                    boxSize="1em"
                                    bg={colors['success-high']}
                                />
                            </Avatar>
                        </div>
                    </MenuButton>

                    <MenuList minWidth="30px" fontSize={'14px'}>
                        <MenuItem
                            icon={<AiOutlineLock />}
                            onClick={() => router.push('/security')}>
                            Seguridad
                        </MenuItem>
                        <MenuItem icon={<AiOutlineLock />} onClick={logout}>
                            Cerrar sesión
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Box>
    )
}

const DesktopNav = () => {
    const { user } = useAuth({ middleware: 'auth' })

    const rol = user?.roles[0].name ?? ROLES.comercial.name
    const isAdminVoucher = user?.is_admin_voucher === 1 ? true : false
    const isAdminDashboard = user?.dashboard ? true : false
    const isSuperAdmin = user?.is_super_admin ? true : false

    const linkColor = useColorModeValue(colors.white, 'gray.200')
    const linkHoverColor = useColorModeValue(colors.tertiary, 'white')
    const popoverContentBgColor = useColorModeValue('white', 'gray.800')

    const allowedLinks = useMemo(() => {
        const { isExtensionRequest, menus } = getExtensionRequestUser(
            user?.profile?.data,
        )

        if (isExtensionRequest) {
            const {
                bonus = true,
                opportunities = true,
                voucherList = true,
            } = menus

            return NAV_ITEMS.reduce((acc, item) => {
                if (item.href === '/' && opportunities) acc.push(item)
                if (item.label === 'Gestión de bono' && bonus) {
                    acc.push({
                        ...item,
                        children: item.children.filter(
                            child => child.href === '/bonus',
                        ),
                    })
                }
                if (item.href === '/bonus-extension' && voucherList)
                    acc.push(item)

                return acc
            }, [])
        }

        return NAV_ITEMS.map(nav => {
            if (nav.href === '/') return nav
            if (nav.label === 'Distribuidores' && !isSuperAdmin) return null
            if (
                nav.href === '/bonus-extension' &&
                (isAdminVoucher || isAdminDashboard || isSuperAdmin)
            )
                return nav

            if (nav.children) {
                return {
                    ...nav,
                    children: nav.children.filter(subnav => {
                        if (subnav.href === '/dashboard' && isAdminDashboard)
                            return subnav

                        if (rol === ROLES.admin.name) return subnav

                        if (
                            user?.profile?.data?.voucherList &&
                            subnav.href === '/bonus'
                        )
                            return subnav

                        if (rol === ROLES.comercial.name) {
                            if (
                                isAdminVoucher &&
                                COMMERCIAL_ADMIN_ROUTES.includes(subnav.href)
                            )
                                return subnav

                            if (
                                !isAdminVoucher &&
                                COMMERCIAL_ROUTES.includes(subnav.href)
                            )
                                return subnav
                        }

                        return null
                    }),
                }
            }
        }).filter(item => item)
    }, [user, isAdminDashboard, isAdminVoucher, isSuperAdmin, rol])

    return (
        <Stack direction={'row'} spacing={4} align="center">
            {allowedLinks.map(navItem => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        {navItem.href === '/' ? (
                            <NextLink href="/">
                                <Link
                                    px={1}
                                    fontSize={15}
                                    fontWeight={500}
                                    color={linkColor}
                                    _hover={{
                                        textDecoration: 'none',
                                        color: linkHoverColor,
                                    }}>
                                    {navItem.label}
                                </Link>
                            </NextLink>
                        ) : (
                            <PopoverTrigger>
                                <Link
                                    px={1}
                                    href={navItem.href ?? '#'}
                                    fontSize={15}
                                    fontWeight={500}
                                    color={linkColor}
                                    _hover={{
                                        textDecoration: 'none',
                                        color: linkHoverColor,
                                    }}>
                                    {navItem.label}
                                </Link>
                            </PopoverTrigger>
                        )}

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map(child => (
                                        <DesktopSubNav
                                            key={child.label}
                                            {...child}
                                        />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <NextLink href={href}>
            <Link
                role={'group'}
                display={'block'}
                p={2}
                rounded={'md'}
                _hover={{
                    bg: useColorModeValue(
                        colors['secondary-soft-hover'],
                        'gray.900',
                    ),
                }}>
                <Stack direction={'row'} align={'center'}>
                    <Box>
                        <Text
                            transition={'all .3s ease'}
                            _groupHover={{ color: colors.secondary }}
                            fontWeight={500}>
                            {label}
                        </Text>
                        <Text fontSize={'sm'}>{subLabel}</Text>
                    </Box>
                    <Flex
                        transition={'all .3s ease'}
                        transform={'translateX(-10px)'}
                        opacity={0}
                        _groupHover={{
                            opacity: '100%',
                            transform: 'translateX(0)',
                        }}
                        justify={'flex-end'}
                        align={'center'}
                        flex={1}>
                        <Icon
                            color={colors.secondary}
                            w={5}
                            h={5}
                            as={ChevronRightIcon}
                        />
                    </Flex>
                </Stack>
            </Link>
        </NextLink>
    )
}
